import * as React from "react";
import {observer} from "mobx-react";
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import EditOffIcon from '@mui/icons-material/EditOff';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ErrorBox from "./ErrorBox";
import CopyTextContainer from "./CopyTextContainer";

function Transcription({audioNoteTranscriptionDown, audioNoteTranscription}) {

  if (audioNoteTranscriptionDown) {
    return (<ErrorBox errorIcon={SyncProblemIcon}
                      errorMessage={"No se puede obtener la transcripción de la nota de audio. Intente nuevamente más tarde!"}/>)
  }

  if (audioNoteTranscription.status.failed) {
    return (<ErrorBox errorIcon={EditOffIcon} errorMessage={"No se puede transcribir la nota de audio."}/>);
  }

  if (!audioNoteTranscription.status.started) {
    return (
      <ErrorBox errorIcon={HourglassTopIcon} errorMessage={"Todavía no se comenzó a transcribir la nota de audio."}/>);
  }

  return (
    <CopyTextContainer textForClipboard={audioNoteTranscription.text}>
      {audioNoteTranscription.text}
    </CopyTextContainer>
  )
}

export default observer(Transcription);
import MaiaFilledIconButton from './buttons/MaiaFilledIconButton';
import { NoteAdd } from '@mui/icons-material';
import * as React from 'react';

export function NewTextNoteButton ({ onClick }) {
  return <MaiaFilledIconButton
    icon={NoteAdd}
    title={'Nueva nota'}
    onClick={onClick}
  />;
}
import * as React from 'react';
import {useApplication} from '../providers/ApplicationProvider';
import {observer} from 'mobx-react';
import Box from '@mui/material/Box';
import {ScreenTitleBar} from '../components/ScreenTitleBar';
import NoteList from '../components/entries_list_items/NoteList';
import RecordButton from '../components/audio/RecordButton';
import useFeature from '../hooks/useFeature';
import {useVoiceAudioRecorder} from '../providers/VoiceAudioRecorderProvider';
import CreateTextNote from '../components/CreateTextNote';
import FileUploadBox from '../components/FileUploadBox';
import useNotificationReceiver from '../hooks/useNotificationReceiver';
import PersonalNotebookFeature from '../features/PersonalNotebookFeature';
import DashboardMainContainer from '../components/DashboardMainContainer';
import ShareNotebookDialog from '../components/share_notebook/ShareNotebookDialog';
import SharePermissionsBox from '../components/SharePermissionsBox';
import {NewTextNoteButton} from '../components/NewTextNoteButton';
import {UploadFileButton} from '../components/UploadFileButton';
import SearchBar from "../components/SearchBar";
import NotesSearchResult from "../components/entries_list_items/NotesSearchResult";
import Patient from "../app/audioOwners/Patient";

function PatientNotesScreen() {
  const application = useApplication();
  const voiceAudioRecorder = useVoiceAudioRecorder();
  const notificationReceiver = useNotificationReceiver();
  const feature = useFeature(() => new PersonalNotebookFeature(application, voiceAudioRecorder, notificationReceiver), [notificationReceiver]);


  return feature && (
    <DashboardMainContainer>
      <ScreenTitleBar title={`Mi legajo`}>
        <Box sx={{display: 'flex', gap: '8px'}}>
          <ShareNotebookDialog wizard={feature.wizard}
                               onOpen={feature.resetWizard}/>
          <UploadFileButton onClick={() => feature.toggleShowUploadFieldForm()}/>
          <NewTextNoteButton onClick={() => feature.toggleShowTextNoteForm()}/>
          <RecordButton person={new Patient()}/>
        </Box>
      </ScreenTitleBar>
      <SearchBar onSearch={feature.searchEntries}
                 searchDisabled={feature.isSearchingResults}
                 placeholder={"Que estás buscando? Por ejemplo: estudios de sangre"}/>
      {feature.hasSharePermissions && <SharePermissionsBox sharePermissions={feature.sharePermissions}
                                                           onRemoveSharePermission={feature.removeSharePermissionToDoctor}/>}
      {feature.showTextNoteForm && <CreateTextNote feature={feature}/>}
      {feature.showUploadFileForm && <FileUploadBox
        onFileUploaded={feature.uploadFileToNotebook}
        onCancel={feature.toggleShowUploadFieldForm}/>}
      <NotesSearchResult feature={feature}
                           showSearchResults={feature.showSearchResults}
                           closeSearchResults={feature.closeSearchResults}
                           isRetrievingData={feature.isRetrievingData}
                           searchedEntries={feature.searchedEntries}
                           owner={new Patient()}/>
      <NoteList
        notes={feature.notes}
        tags={feature.tags}
        isRetrievingData={feature.isRetrievingData}
        updateEntryTitle={feature.updateEntryTitle}
        updateEntryTags={feature.updateEntryTags}
        toggleEntryVisibility={feature.toggleEntryVisibility}
        audioNotesOwner={new Patient()}
        onDeleteAudioNote={feature.deleteAudioNoteAndRefresh}
        onDeleteTextNote={feature.deleteTextNoteAndRefresh}
        onDeleteFile={feature.deleteFileAndRefresh}
      />

    </DashboardMainContainer>
  );
}

export default observer(PatientNotesScreen);

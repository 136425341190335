import ApiResponseHandler from '@eryxcoop/appyx-comm/src/errors/ApiResponseHandler';
import NotebookFeature from './NotebookFeature';
import EmailField from "../libs/forms/fields/EmailField";
import OptionField from "../libs/forms/fields/OptionField";
import MaiaForm from "../libs/forms/MaiaForm";
import {action, computed, makeObservable, observable} from "mobx";
import Wizard from "../libs/wizards/Wizard";
import {FormWizardStep} from "../libs/wizards/FormWizardStep";
import TextField from "../libs/forms/fields/TextField";
import {toast} from "react-toastify";

export default class PersonalNotebookFeature extends NotebookFeature {

  constructor(application, voiceAudioRecorder, notificationReceiver) {
    super(application, voiceAudioRecorder, notificationReceiver);

    this._form = this._createShareNotebookForm();
    this._wizard = this._createWizard();
    this._searchingResults = false;
    this._gettingDoctorInformation = false;
    this._sharePermissions = [];

    makeObservable(this, {
      _form: observable,
      _wizard: observable,
      _searchingResults: observable,
      _sharePermissions: observable,
      _gettingDoctorInformation: observable,
      _getDoctorsInformation: action,
      _refreshSharePermissions: action,
      gettingDoctorInformation: computed,
      hasSharePermissions: computed,
      isSearchingResults: computed,
      resetWizard: action,
      form: computed,
      wizard: computed,
      searchEntries: action,
      closeSearchResults: action,
    });
  }

  _onLoad = () => {
    if (this._application.user.isPatient()) {
      this._refreshSharePermissions();
    }
  }

  sharingTimeOptionsSelect() {
    return [
      {label: '1 hora', value: '1'},
      {label: '12 horas', value: '12'},
      {label: '1 día', value: '24'},
      {label: '1 semana', value: '168'},
      {label: 'Indefinido', value: '0'},
    ]
  }

  sharingTimeOptionsValues() {
    return this.sharingTimeOptionsSelect().map((option) => {
      return option.value;
    });
  }

  getSharingTimeLabel = (value) => {
    return this.sharingTimeOptionsSelect().find(option => option.value === value).label
  }

  _getDoctorsInformation = () => {
    const responseHandler = new ApiResponseHandler({
      handlesError: () => {
        this._gettingDoctorInformation = false;
        toast.error("No se encontró al doctor en el sistema.");
        this.form.clear();
      },
      handlesSuccess: (response) => {
        const fullName = response.fullName();
        this.form.getFieldByName("doctorFullName").updateValue(fullName);
        this._gettingDoctorInformation = false;
        this._wizard.moveToNextStep();
      }
    });
    this._gettingDoctorInformation = true;
    const email = this.form.getFieldValue("email");
    this._application.apiClient().getDoctorInformation(email, responseHandler);
  }

  _shareNotebookWithDoctor = () => {
    const responseHandler = new ApiResponseHandler({
      handlesError: () => {
        this._gettingDoctorInformation = false;
        toast.error("No se pudo.");
        this.getPatientProfile();
      },
      handlesSuccess: () => {
        this._gettingDoctorInformation = false;
        this._wizard.moveToNextStep();
        this._refreshSharePermissions();
      }
    });
    this._gettingDoctorInformation = true;
    const doctorEmail = this.form.getFieldValue("email");
    const sharingTime = this.form.getFieldValue("sharingTime");
    this._application.apiClient().shareNotebookWithDoctor(doctorEmail, sharingTime, responseHandler);
  }

  removeSharePermissionToDoctor = (doctorEmail) => {
    const responseHandler = new ApiResponseHandler({
      handlesError: () => {
        toast.error("No se pudo eliminar el permiso.");
      },
      handlesSuccess: () => {
        this._refreshSharePermissions();
      }
    });
    this._application.apiClient().removeSharePermissionToDoctor(doctorEmail, responseHandler);
  }

  _refreshSharePermissions = () => {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: (response) => {
        this._sharePermissions = response.sharePermissions();
      }
    });
    this._application.apiClient().getSharePermissions(responseHandler);
  }

  _createShareNotebookForm() {
    const sharingTimeOptions = this.sharingTimeOptionsValues();
    const fields = {
      "email": new EmailField(),
      "doctorFullName": new TextField(0, 100).asOptional(),
      "sharingTime": new OptionField(sharingTimeOptions),
    }

    return new MaiaForm(fields);
  }

  resetWizard = () => {
    this._wizard.restart();
    this._form.clear();
  }

  _createWizard() {
    const wizard = new Wizard();
    wizard.addSteps(
      [
        new FormWizardStep(
          {
            loading: () => {
              return this.gettingDoctorInformation
            },
            onContinue: () => this._getDoctorsInformation(),
            extraProps: {
              form: this.form,
              sharingTimeOptionsSelect: this.sharingTimeOptionsSelect()
            }
          }
        ),
        new FormWizardStep(
          {
            loading: () => {
              return this.gettingDoctorInformation
            },
            onContinue: this._shareNotebookWithDoctor,
            onBack: () => this._wizard.moveToPreviousStep(),
            extraProps: {
              form: this.form,
              getSharingTimeLabel: this.getSharingTimeLabel
            }
          }
        ),
        new FormWizardStep(
          {
            loading: () => false,
            extraProps: {}
          }
        )
      ]
    );
    return wizard;
  }

  get form() {
    return this._form;
  }

  get wizard() {
    return this._wizard;
  }

  get sharePermissions() {
    return this._sharePermissions;
  }

  get hasSharePermissions() {
    return this._sharePermissions.length > 0;
  }

  get isSearchingResults() {
    return this._searchingResults;
  }

  get gettingDoctorInformation() {
    return this._gettingDoctorInformation;
  }

  async _saveTextNote(title, content, responseHandler) {
    await this._application.apiClient().createTextNote(title, content, responseHandler);
  }

  async _saveFile(file, responseHandler) {
    await this._application.apiClient().saveFileToNotebook(file, responseHandler);
  }

  _deleteEntry(entry, responseHandler) {
    this._application.apiClient().deleteNotebookEntry(entry, responseHandler);
  }

  _updateEntryTitle(entry, title, responseHandler) {
    this._application.apiClient().updateNotebookEntryTitle(entry, title, responseHandler);
  }

  _updateEntryTags(entry, tags, responseHandler) {
    this._application.apiClient().updateNotebookEntryTags(entry, tags, responseHandler);
  }

  async _refreshNotebook() {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: (response) => {
        this._updateNotes(response.notebookEntries());
      }
    });
    await this._application.apiClient().getNotebookEntries(responseHandler);
  }

  searchEntries = async (searchQuery) => {
    this._searchingResults = true
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: (response) => {
        this._searchedEntries = response.entries();
        this._searchingResults = false;
        this._showSearchResults = true;
      }
    });
    await this._application.apiClient().getEntriesBySearch(responseHandler, searchQuery);
  }

  toggleEntryVisibility = (note) => {
    if (note.isPublic) {
      this._markNotebookEntryAsPrivate(note);
    } else {
      this._markNotebookEntryAsPublic(note);
    }
  }

  _markNotebookEntryAsPrivate(note) {
    note.setVisibility(false);
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: () => {
      },
      handlesError: () => {
        toast.error("No fue posible actualizar la entrada.");
        note.setVisibility(true);
      }
    });
    this._application.apiClient().markNotebookEntryAsPrivate(note, responseHandler);
  }

  _markNotebookEntryAsPublic(note) {
    note.setVisibility(true);
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: () => {
      },
      handlesError: () => {
        toast.error("No fue posible actualizar la entrada.");
        note.setVisibility(false);
      }
    });
    this._application.apiClient().markNotebookEntryAsPublic(note, responseHandler);
  }
}
import * as React from 'react';
import {useApplication} from '../providers/ApplicationProvider';
import {observer} from 'mobx-react';
import Box from '@mui/material/Box';
import {ScreenTitleBar} from '../components/ScreenTitleBar';
import NoteList from '../components/entries_list_items/NoteList';
import Doctor from '../app/audioOwners/Doctor';
import RecordButton from '../components/audio/RecordButton';
import useFeature from '../hooks/useFeature';
import PersonalNotebookFeature from '../features/PersonalNotebookFeature';
import {useVoiceAudioRecorder} from '../providers/VoiceAudioRecorderProvider';
import CreateTextNote from '../components/CreateTextNote';
import FileUploadBox from '../components/FileUploadBox';
import useNotificationReceiver from '../hooks/useNotificationReceiver';
import DashboardMainContainer from '../components/DashboardMainContainer';
import {NewTextNoteButton} from '../components/NewTextNoteButton';
import {UploadFileButton} from '../components/UploadFileButton';
import SearchBar from "../components/SearchBar";
import NotesSearchResult from "../components/entries_list_items/NotesSearchResult";

function DoctorsNotesScreen() {
  const application = useApplication();
  const voiceAudioRecorder = useVoiceAudioRecorder();
  const notificationReceiver = useNotificationReceiver();
  const feature = useFeature(() => new PersonalNotebookFeature(application, voiceAudioRecorder, notificationReceiver), [notificationReceiver]);
  const style = styles();

  return feature && (
    <DashboardMainContainer>
      <ScreenTitleBar title={`Mis notas`}>
        <Box sx={style.rightBarContainer}>
          <UploadFileButton onClick={() => feature.toggleShowUploadFieldForm()}/>
          <NewTextNoteButton onClick={() => feature.toggleShowTextNoteForm()}/>
          <RecordButton person={new Doctor()}/>
        </Box>
      </ScreenTitleBar>
      <SearchBar onSearch={feature.searchEntries}
                 searchDisabled={feature.isSearchingResults}
                 placeholder={"Que estás buscando? Por ejemplo: estudios de sangre"}/>
      {feature.showTextNoteForm && <CreateTextNote feature={feature}/>}
      {feature.showUploadFileForm && <FileUploadBox
        onFileUploaded={feature.uploadFileToNotebook}
        onCancel={feature.toggleShowUploadFieldForm}/>}
      <NotesSearchResult feature={feature}
                           showSearchResults={feature.showSearchResults}
                           closeSearchResults={feature.closeSearchResults}
                           isRetrievingData={feature.isRetrievingData}
                           searchedEntries={feature.searchedEntries}
                           owner={new Doctor()}/>
      <NoteList
        notes={feature.notes}
        tags={feature.tags}
        isRetrievingData={feature.isRetrievingData}
        updateEntryTitle={feature.updateEntryTitle}
        updateEntryTags={feature.updateEntryTags}
        audioNotesOwner={new Doctor()}
        onDeleteAudioNote={feature.deleteAudioNoteAndRefresh}
        onDeleteTextNote={feature.deleteTextNoteAndRefresh}
        onDeleteFile={feature.deleteFileAndRefresh}
      />

    </DashboardMainContainer>
  );
}

export default observer(DoctorsNotesScreen);

const styles = () => ({
  rightBarContainer: {
    display: 'flex',
    gap: '8px'
  }
});
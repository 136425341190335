import useWebSocket from 'react-use-websocket';
import { useApplication } from '../providers/ApplicationProvider';
import { useState } from 'react';
import NotificationReceiver from "../app/NotificationReceiver";

const useNotificationReceiver = () => {
  const application = useApplication();
  const [notificationReceiver, setNotificationReceiver] = useState(null);

  useWebSocket(application.websocketUrl(), {
    onOpen: () => {setNotificationReceiver(new NotificationReceiver())},
    onMessage: (event) => { notificationReceiver.messageReceived(event.data) },
    heartbeat: true,
    shouldReconnect: () => true
  });

  return notificationReceiver;
};

export default useNotificationReceiver;